<template>
  <v-textarea outlined
              v-bind="$attrs"
              v-on="$listeners" />
</template>

<script>
export default {
  name: "AppTextarea"
}
</script>

<style scoped>

</style>