import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"surface"},[_c(VTabs,{staticClass:"background mb-3",attrs:{"grow":"","show-arrows":"","vertical":_vm.$vuetify.breakpoint.smAndDown ? true : false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.menuItems),function(item){return _c(VTab,{key:item.name},[_c(VIcon,[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),1),_c(VTabsItems,{attrs:{"elevation":"0"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{key:"Calendar",attrs:{"eager":""}},[_c('booking-system-calendar',{attrs:{"booking-categories":_vm.categoryValues,"events":_vm.events,"loading":_vm.loading},on:{"updateCalendarRange":function($event){return _vm.updateCalendarRange($event)}}})],1),_c(VTabItem,{key:"Table",attrs:{"eager":""}},[_c('booking-system-table',{attrs:{"events":_vm.events}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }