<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          class="px-2"
          :value="computedDateFormatted"
          clearable
          :label="label"
          prepend-inner-icon="mdi-calendar"
          :disabled="disabled"
          readonly
          outlined
          dense
          v-show="show"
          v-bind="attrs"
          v-on="on"
          @click:clear="clearValue"
          :rules="rules"
      />
    </template>
    <v-date-picker
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        :min="min"
        :max="max"
        :disabled="disabled"
        @input="menu = false"
    />
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
name: "AppDatePicker",
  props: {
    value:{},
    max:{},
    min:{},
    disabled:{
      type: Boolean,
      default: false
    },
    show:{
      type: Boolean,
      default: true
    },
    label:{
      type: String,
      default: 'Select date'
    },
  },
  data() {
    return {
      menu:false,
      localDate: null,
      rules: [
        v => !!v || 'selection is required',
      ],
    }
  },
  computed: {
    computedDateFormatted () {
      return this.value ? moment(this.value).format('LL') : ''
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value)
      this.localDate = moment(value).format('dddd, MMMM Do YYYY')
      this.menu = false;
    },
    clearValue() {
      this.$emit("input", null)
      this.localDate = ''
      this.menu = false;
    },
  }
}
</script>

<style scoped>

</style>