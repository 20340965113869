<template>
  <v-container>
    <v-row class="mt-6">
      <v-col cols="12" sm="12" md="8">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface"
        >
          <v-sheet rounded="lg" class="background pt-2 my-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bookings
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-layout row wrap>
                    <div>
                      <v-dialog
                          v-model="dialog"
                          width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              color="secondary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-icon left>mdi-filter</v-icon>
                            Filter
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Booking filters
                          </v-card-title>
                          <v-card-text>
                            Use filter to help find posts of interest.
                          </v-card-text>
                          <v-card-text>
                            <div class="text-body-1">
                              <app-select v-model="selectedViewableEvents" :items="bookingCategories" clearable
                                          :label="`Filter ${selectedViewableEvents ? '(press x to clear)':''}`"
                                          max-width="60px"
                                          prepend-icon="mdi-filter">
                              </app-select>
                            </div>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                @click="clearFilters"
                            >
                              Reset
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="dialog = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <app-btn color="primary" @click="newBooking"><v-icon>mdi-calendar</v-icon>New</app-btn>
                  </v-layout>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="my-2"/>
            <booking-system :loading="loading" :events="calendarBookings" v-on:updateCalendarRange="updateCalendarRange($event)"/>
            <booking-system-event-modal v-on:refreshBookings="refreshBookings" />
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface"
        >
          <scoped-event-list :items="usersBookings" :owner="loggedInUser._id"/>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions, mapState} from "vuex";
import ScopedEventList from "@/components/shared/ScopedEventList";
import BookingSystemEventModal from "@/components/modals/BookingSystemEventModal";
import AppSelect from "@/components/app/Select";
import AppBtn from "@/components/app/Btn";
import BookingSystem from "@/components/shared/BookingSystem";

export default {
  name: 'Bookings',
  computed: {
    ...mapState("app", ["loading", "loggedInUser"]),
    ...mapState("bookings", ["bookingCategories", "bookings"]),
    usersBookings() {
      return this.bookings.filter(b => b.owner === this.loggedInUser._id && b.end > Date.now()).sort((a, b) => (a.start > b.start) ? 1 : -1)
    },
    calendarBookings() {
      return this.selectedViewableEvents ? this.bookings.filter(e => e.category == this.selectedViewableEvents).sort((a, b) => (a.start > b.start) ? 1 : -1) : this.bookings
    }
  },
  components: {BookingSystem, AppBtn, AppSelect, BookingSystemEventModal, ScopedEventList},
  data() {
    return {
      dialog: false,
      selectedViewableEvents: null,
      start:null,
      end:null,
    }
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("bookings", ["setBookings","setActiveBooking", "setManageBookingDialog"]),

    clearFilters() {
      this.selectedViewableEvents = null
      this.dialog = false
    },
    async newBooking() {
      await this.setActiveBooking({})
      await this.setManageBookingDialog(true)
    },

    async updateCalendarRange({start, end}) {
      this.start = new Date(`${start.date}T00:00:00`).toISOString()
      this.end = new Date(`${end.date}T23:59:59`).toISOString()
      await this.refreshBookings()
    },
    async refreshBookings() {
      await this.setLoading(true)
      await this.setBookings({groupId: 'bfc', start: this.start, end: this.end})
      await this.setLoading(false)
    }
  }
};
</script>