<template>
  <v-card rounded="lg" outlined class="my-2">
    <div>
      <v-list-item>
        <v-list-item-content class="mt-2">
          <v-list-item-title class="subtitle">
            My Future Bookings
          </v-list-item-title>
          <v-list-item-subtitle class="caption indigo--text">
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <app-btn fab text
                   small
                   @click="newBooking()">
            <v-icon>mdi-plus</v-icon>
          </app-btn>
        </v-list-item-action>
      </v-list-item>
    </div>
    <v-divider/>
    <v-item-group>
      <v-list dense>
        <v-list-item class="rounded" v-if="!someBookings">
          <v-list-item-content>
            <v-list-item-title class="subtitle">
              You do not have any Bookings
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              Why not start planning your next trip?
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="item in items" :key="item._id" class="rounded">
          <v-list-item-content>
            <v-list-item-title class="subtitle">
              {{ item.start | moment('DD MMM') }} {{ item.category }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ item.start | moment('HH:mm') }} - {{ item.end | moment('HH:mm') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <v-col>
                <v-btn fab text color="primary" @click="showEvent(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </v-col>
            </v-row>

          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-item-group>
  </v-card>
</template>
<script>
import {mapState, mapActions} from "vuex";
import AppBtn from "@/components/app/Btn";

export default {
  components: {AppBtn},
  props: {
    items: Array,
    owner: String,
  },
  computed: {
    ...mapState("bookings", ["bookings", "activeBooking",]),
    ...mapState("app", ["loggedInUser"]),
    someBookings() {
      return this.items.length > 0 ? true : false
    },
    myBookings() {
      if (!this.loggedInUser._id || this.loggedInUser.length === 0) {

        return this.bookings
      } else {
        return this.bookings.filter(booking => {
          return booking.booker._id === this.loggedInUser._id
        }).sort((a, b) => a[this.propertyToSort] < b[this.propertyToSort] ? -1 : 1)
      }

    },

  },
  methods: {
    ...mapActions("bookings", ["setActiveBooking", "setBookings", "setManageDeleteBookingDialog", "setManageBookingDialog"]),
    async newBooking() {
      await this.setActiveBooking({})
      await this.setManageBookingDialog(true)
    },
    async showEvent(event) {
      await this.setActiveBooking(event)
      await this.setManageBookingDialog(true)
    },
    async editBooking(item) {
      await this.setActiveBooking(item);
      await this.setManageBookingDialog(true);
    },
    async deleteBooking(item) {
      await this.setActiveBooking(item);
      await this.setManageDeleteBookingDialog(true);
    },

    sortBy(prop) {
      this.propertyToSort = prop
    }
  }
};
</script>

<style>
.sortie.late {
  border-left: 4px solid tomato !important;
}

.sortie.complete {
  border-left: 4px solid mediumpurple !important;
}

.sortie.awaiting.flight.details {
  border-left: 4px solid orange !important;
}

.sortie.in.progress {
  border-left: 4px solid blue !important;
}

.sortie.scheduled {
  border-left: 4px solid grey !important;
}

.v-chip.late {
  background: #ff0000 !important;
}

.v-chip.complete {
  background: mediumpurple !important;
}

.v-chip.awaiting.flight.details {
  background: orange !important;
}

.v-chip.in.progress {
  background: blue !important;
}

.v-chip.isScheduled {
  background: orange !important;
}
</style>