<template>
  <v-dialog
      ref="dialog"
      v-model="modal"
      persistent
      width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          class="px-2"
          :value="value"
          :label="label"
          outlined
          dense
          readonly
          clearable
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
          @input="updateValue($event)"
      ></v-text-field>
    </template>
    <v-time-picker
        format="24hr"
        v-if="modal"
        :disabled="disabled"
        :value="value"
        :max="max"
        :min="min"
        full-width
        @input="updateValue($event)"
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="modal = false"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "AppTimePicker",
  props: {
    value: {},
    max: {},
    min: {},
    disabled: {},
    label: {
      type: String,
    }
  },
  data() {
    return {
      modal: false
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value)
      this.menu = false;
    },
  }
}
</script>

<style scoped>

</style>