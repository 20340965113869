import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({staticClass:"px-2",attrs:{"value":_vm.value,"label":_vm.label,"outlined":"","dense":"","readonly":"","clearable":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.updateValue($event)}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c(VTimePicker,{attrs:{"format":"24hr","disabled":_vm.disabled,"value":_vm.value,"max":_vm.max,"min":_vm.min,"full-width":""},on:{"input":function($event){return _vm.updateValue($event)}}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" OK ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }