<template>
  <v-dialog v-model="manageBookingDialog" width="600px" persistent v-if="activeBooking">
    <v-card flat class="pb-4">
      <v-toolbar
          :color="activeBooking.color"
          dark
      >
        <app-btn fab small @click="isReadOnly=!isReadOnly" class="mr-2"
                 v-if="canAmendBooking">
          <v-icon>mdi-{{ isReadOnly ? "pencil" : "binoculars" }}</v-icon>
        </app-btn>
        <v-toolbar-title>{{ isNewBooking ? 'Create Booking' : activeBooking.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <app-btn small @click="deleteSortie" v-if="canDeleteBooking">
          <v-icon>mdi-delete-forever</v-icon>
        </app-btn>
      </v-toolbar>
      <v-alert
          border="left"
          colored-border
          elevation="2"
          type="error"
          v-if="bookingsOfSameType.length >1 && !isReadOnly || bookingsOfSameType.length !==0 && isNewBooking && localEndTime">
        <div class="text-body-1 mb-2">Booking Conflicts:</div>
        <div class="text-body-2" v-for="booking in bookingsOfSameType" :key="booking._id">
          <div>
            {{ isOwner(booking.owner) ? 'You already have' : booking.name + ' already has' }} a booking between
            {{ booking.start | moment('DD MMM HH:mm') }} -
            {{ booking.end | moment('DD MMM HH:mm') }}
          </div>
        </div>
        <div class="caption mt-2">You will need to modify your booking so that it does not overlap with a booking that
          already exists for {{ activeBooking.category }}.
        </div>
      </v-alert>
      <v-form class="px-2" ref="form">
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <div class="caption">Booking Category</div>
              <app-select v-model="activeBooking.category" :items="bookingCategories"
                          v-if="canAmendBooking || isNewBooking"
                          :disabled="loading || isReadOnly && !isNewBooking"/>
              <div class="text-body-2" v-else>{{ activeBooking.category }}</div>
            </v-flex>
            <v-container>
              <v-row v-if="activeBooking.category">
                <v-col cols="6">
                  <div class="text-caption grey--text">Start:<span>{{
                      displayDate(localStartDate)
                    }} {{ localStartTime ? localStartTime || $moment('HH:mm') : '' }} </span></div>
                </v-col>
                <v-col cols="6">
                  <div class="text-caption grey--text">End:<span>{{
                      displayDate(localEndDate)
                    }} {{ localEndTime ? localEndTime || $moment('HH:mm') : '' }} </span></div>
                </v-col>
                <v-col cols="6">

                  <app-date-picker :disabled="loading || isReadOnly && !isNewBooking" v-model="localStartDate"
                                   :min="dateToday" @input="updateStart"/>
                  <app-time-picker v-model="localStartTime" :disabled="loading || isReadOnly && !isNewBooking"
                                   :min="localStartDate === dateToday ? timeNow: undefined"
                                   :max="localEndDate === localStartDate ? localEndTime: undefined"
                                   @input="updateStart"/>
                </v-col>
                <v-col cols="6">
                  <app-date-picker :disabled="true" v-model="localEndDate" v-show="localStartDate"
                                   :min="localStartDate" @input="updateEnd"/>
                  <app-time-picker v-model="localEndTime" :disabled="loading || isReadOnly && !isNewBooking"
                                   :min="localEndDate === localStartDate ? localStartTime: undefined"
                                   @input="updateEnd"/>
                </v-col>
                <v-col cols="12">
                  <v-radio-group row label="Quick Select" v-model="selectedRangeQuickSelect"
                                 :disabled="loading || isReadOnly && !isNewBooking" @change="updateStart && updateEnd">
                    <v-radio v-for="item in rangeQuickSelect" :key="item" :value="item" :label="item"/>
                  </v-radio-group>

                </v-col>
              </v-row>
            </v-container>
            <v-flex xs12>
              <div class="caption">Booking Notes</div>
              <app-textarea
                  v-if="canAmendBooking || isNewBooking"
                  :disabled="loading || isReadOnly && !isNewBooking"
                  v-model="activeBooking.details"
                  type="text"
                  style="width: 100%"
                  :min-height="100"
                  placeholder="add any notes to the booking. This is viewable by all members of the group."/>
              <div class="text-body-2" v-else>{{ activeBooking.details }}</div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout row wrap class="justify-end">
            <v-spacer/>
            <v-flex class="px-2">
              <v-btn text
                     class="secondary mx-0 mt-3"
                     @click="closeDialog"
                     :loading="loading"
                     :disabled="loading">Close
              </v-btn>
            </v-flex>
            <v-flex class="px-2">
              <v-btn text
                     v-if="canAmendBooking && !isReadOnly || isNewBooking"
                     class="primary mx-0 mt-3"
                     @click="saveBooking"
                     :loading="loading"
                     :disabled="loading || !formValid || isNewBooking ? bookingsOfSameType.length != 0: bookingsOfSameType.length > 1">
                Save
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex"
import {BookingsHttp} from "@/http/BookingsHttp"
import moment from 'moment';
import AppSelect from "@/components/app/Select";
import AppBtn from "@/components/app/Btn";
import AppTextarea from "@/components/app/Textarea";
import AppDatePicker from "@/components/app/DatePicker";
import AppTimePicker from "@/components/app/TimePicker";

export default {
  name: "BookingSystemEventModal",
  components: {AppTimePicker, AppDatePicker, AppTextarea, AppBtn, AppSelect},
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      isReadOnly: true,
      localStartDate: null,
      localStartTime: null,
      localEndDate: null,
      localEndTime: null,
      selectedRangeQuickSelect: null,
      rangeQuickSelect: ["All Day", "AM", "PM"],
    }
  },
  computed: {
    ...mapState("bookings", ["bookingCategories", "manageBookingDialog", "activeBooking", "bookings"]),
    ...mapState("person", ["persons"]),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("user", ["flyingStatus","accountRoles","groupMembership"]),

    dateToday() {
      return moment().format('YYYY-MM-DD')
    },
    timeNow() {
      return moment().format('HH:mm')
    },
    isNewBooking() {
      if (this.activeBooking) {
        if (this.activeBooking.owner) {
          return false
        }
      }
      return true
    },
    notHistoricalOrNewBooking() {
      return !this.isNewBooking && !this.historicalBooking
    },
    canAmendBooking() {
      return this.activeBooking.owner === this.loggedInUser._id && this.notHistoricalOrNewBooking || this.accountRoles.findIndex(r=> r.name === "manageBookings") > -1 && this.notHistoricalOrNewBooking || this.accountRoles.findIndex(r=> r.name === "rootAdmin") > -1 && this.notHistoricalOrNewBooking
    },
    canDeleteBooking() {
      return this.activeBooking.owner === this.loggedInUser._id && this.notHistoricalOrNewBooking || this.accountRoles.findIndex(r=> r.name === "manageBookings") > -1 && this.notHistoricalOrNewBooking || this.accountRoles.findIndex(r=> r.name === "rootAdmin") > -1 && this.notHistoricalOrNewBooking
    },

    formValid() {
      return this.activeBooking.category &&
      this.localStartDate &&
      this.localStartTime &&
      this.localEndDate &&
      this.localStartTime !== this.localEndDate &&
      this.localEndTime ? true : false &&
          this.bookingsOfSameType.length === 0
    },
    historicalBooking() {
      return moment().isSameOrAfter(this.activeBooking.end) && moment().isSameOrAfter(this.activeBooking.start)
    },
    bookingsOfSameType() {
      return this.bookings.filter(b => b.category === this.activeBooking.category && !moment(b.end).isSameOrBefore(moment(this.localStartDate + 'T' + this.localStartTime), 'second') && !moment(b.start).isSameOrAfter(moment(this.localEndDate + 'T' + this.localEndTime), 'second'))
    },
  },
  watch: {
    activeBooking(newVal) {
      if (newVal.start && newVal.end) {
        this.localStartDate = moment(newVal.start).format('yyyy-MM-DD')
        this.localStartTime = moment(newVal.start).format('HH:mm')
        this.localEndDate = moment(newVal.end).format('yyyy-MM-DD')
        this.localEndTime = moment(newVal.end).format('HH:mm')
      }
    },
    localStartDate(newVal, oldVal) {
      if (oldVal == null) {
        this.localEndDate = newVal
      }
    },
    selectedRangeQuickSelect(newVal) {
      switch (newVal) {
        case 'All Day':
          this.localStartTime = "00:00"
          this.localEndTime = "23:59"
          return
        case 'AM':
          this.localStartTime = "00:00"
          this.localEndTime = "12:00"
          return
        case 'PM':
          this.localStartTime = "12:00"
          this.localEndTime = "23:59"
          return
        default:
          return
      }
    },

  },
  methods: {
    ...mapActions("bookings", ["setBookings", "setActiveBooking", "setManageBookingDialog"]),
    ...mapActions("person", ["setPersons"]),
    ...mapActions("app", ["setToast", "setLoading"]),

    displayDate(date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },

    updateStart() {
      this.activeBooking.start = this.localStartDate + 'T' + this.localStartTime
      this.localEndDate = this.localStartDate
      this.updateEnd()
    },

    updateEnd() {
      this.activeBooking.end = this.localEndDate + 'T' + this.localEndTime
    },

    async refreshBookings() {
      this.$emit('refreshBookings')
    },
    isOwner(id) {
      return id === this.activeBooking.owner
    },

    async closeDialog() {
      this.isReadOnly = true
      this.localStartDate = null
      this.localStartTime = null
      this.localEndDate = null
      this.localEndTime = null
      this.selectedRangeQuickSelect = null
      await this.setActiveBooking({})
      await this.refreshBookings();
      await this.setManageBookingDialog(false);
    },
    async saveBooking() {
      if (this.$refs.form.validate()) {
        try {
          await this.setLoading(true)

          this.activeBooking.start = moment(this.localStartDate + 'T' + this.localStartTime).unix()
          this.activeBooking.end = moment(this.localEndDate + 'T' + this.localEndTime).unix()
          this.activeBooking.gmtOffset = moment.unix(this.activeBooking.end).utcOffset() * 60
          this.activeBooking.lastUpdatedById = this.loggedInUser._id
          this.activeBooking.lastUpdatedByName = this.loggedInUser.name

          if (this.isNewBooking) {
            this.activeBooking.name = this.loggedInUser.name
            const activeCategory = this.bookingCategories.find(category => category.value === this.activeBooking.category);

            if (activeCategory) {
              this.activeBooking.color = activeCategory.color;
              this.activeBooking.timed = activeCategory.timed;
            } else {
              // Default values if the category is not found
              this.activeBooking.color = "#cc33ff";
              this.activeBooking.timed = true;
            }

            this.activeBooking.owner = this.loggedInUser._id

            await BookingsHttp.createNewBooking(this.activeBooking)
          } else {
            await BookingsHttp.updateBooking(this.activeBooking)
          }
          await this.refreshBookings()
          this.setToast({color: 'success', text: 'Booking updated successfully', show: true})
        } catch (err) {
          if (err.response) {
            console.log(err.response.data);
          } else {
            console.log(err)
          }
        } finally {
          this.localStartDate = null
          this.localStartTime = null
          this.localEndDate = null
          this.localEndTime = null
          this.selectedRangeQuickSelect = null
          await this.setActiveBooking({})
          await this.setManageBookingDialog(false)
          await this.setLoading(false)
          this.isReadOnly = true
        }
      }
    },
    async deleteSortie() {
      if (confirm(`are you sure you want to delete the booking"?`)) {
        try {
          await BookingsHttp.deleteBooking(this.activeBooking._id);
          await this.setBookings(this.groupMembership[0]._id)
        } catch (err) {
          if (err.response) {
            console.log(err.response.data);
          } else {
            console.log(err);
          }
        } finally {
          await this.setActiveBooking({})
          await this.setManageBookingDialog(false);
          await this.refreshBookings()
        }
      }
    }
  }
};
</script>

<style scoped>

</style>