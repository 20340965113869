<template>
  <v-sheet class="surface">
    <v-tabs
        v-model="tab"
        class="background mb-3"
        grow
        show-arrows
        :vertical="$vuetify.breakpoint.smAndDown ? true : false">
      <v-tab
          v-for="item in menuItems"
          :key="item.name"
      >
        <v-icon>{{ item.icon }}</v-icon>
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" elevation="0">
      <v-tab-item eager key="Calendar">
        <booking-system-calendar :booking-categories="categoryValues"
                                 :events="events" :loading="loading"
                                 v-on:updateCalendarRange="updateCalendarRange($event)"/>
      </v-tab-item>
      <v-tab-item eager key="Table">
        <booking-system-table :events="events"/>
      </v-tab-item>
    </v-tabs-items>
  </v-sheet>
</template>

<script>
import BookingSystemCalendar from "@/components/shared/BookingSystemCalendar";
import {mapActions, mapState} from "vuex";
import BookingSystemTable from "@/components/shared/BookingSystemTable";

export default {
  name: "BookingSystem",
  props: {
    loading: {
      type: Boolean
    },
    events: {}
  },
  components: {
    BookingSystemTable,
    BookingSystemCalendar
  },
  data() {
    return {
      tab: null,
      menuItems: [{icon: "mdi-calendar", name: "Calendar"}, {icon: "mdi-table", name: "Table"}],
      start: null,
      end: null,
    }
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    ...mapState("bookings", ["bookingCategories", "bookings"]),

    categoryValues() {
      return this.bookingCategories.map(category => category.value);
    }
  },
  methods: {
    async updateCalendarRange(e) {
      this.$emit("updateCalendarRange",e)
    }
  }
}
</script>