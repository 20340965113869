<template>
  <v-sheet rounded="lg" class="">
    <v-container>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar
                flat
            >
              <app-btn
                  small
                  outlined
                  class="mr-1"
                  color="grey darken-2"
                  @click="setToday"
              >
                Today
              </app-btn>
              <app-btn
                  fab
                  text
                  x-small
                  color="grey darken-2"
                  @click="prev"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </app-btn>
              <v-btn
                  fab
                  text
                  x-small
                  color="grey darken-2"
                  @click="next"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }} - All Times Local
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-select
                  v-model="mode"
                  :items="modes"
                  dense
                  outlined
                  hide-details
                  label="event-overlap-mode"
                  class="ma-2"
              ></v-select>
              <v-menu
                  bottom
                  right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      small
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
<!--                  <v-list-item @click="type = 'category'">-->
<!--                    <v-list-item-title>Day(Cat)</v-list-item-title>-->
<!--                  </v-list-item>-->
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :weekdays="weekday"
                :categories="bookingCategories"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:time="createEvent"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
            >
              <template v-slot:event="{ event }">
                <v-row class="px-3">
                  <v-col cols="4">
                    <div class="caption">{{ event.start | moment("HH:mm") }}-{{ event.end | moment("HH:mm") }}
                      {{ event.name }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="caption"></div>
                  </v-col>
                  <v-spacer/>
                </v-row>
              </template>

            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import {mapActions} from "vuex"
import AppBtn from "@/components/app/Btn";

export default {
  name: "BookingSystemCalendar",
  props: {
    events: {
      type: Array
    },
    bookingCategories: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  components: {AppBtn},
  data() {
    return {
      focus: '',
      type: 'month',
      weekday: [1, 2, 3, 4, 5, 6, 0],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
        category: 'Day',
      },
      selectedViewableEvents: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      mode: 'stack',
      modes: ['stack', 'column'],
    }
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("bookings", ["setBookings", "setActiveBooking", "setManageBookingDialog"]),

    viewDay({date}) {
      this.selectedViewableEvents = null
      this.focus = date
      this.type = 'category'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    async newBooking() {
      await this.setLoading(true)

      await this.setActiveBooking({})
      await this.setManageBookingDialog(true)

      await this.setLoading(false)
    },
    async createEvent(tms) {
      await this.setLoading(true)
      const mouse = this.toTime(tms)
      this.createStart = new Date(this.roundTime(mouse))
      await this.setActiveBooking({
        start: this.createStart,
        end: new Date(this.createStart).setHours(this.createStart.getHours() + 1),
      })
      await this.setManageBookingDialog(true)

      await this.setLoading(false)

    },
    async showEvent({nativeEvent, event}) {
      await this.setLoading(true)

      await this.setActiveBooking(event)
      await this.setManageBookingDialog(true)
      nativeEvent.stopPropagation()
      await this.setLoading(false)
    },
    async updateRange({start, end}) {
      // await this.setLoading(true)
      // const min = new Date(`${start.date}T00:00:00`).toISOString()
      // const max = new Date(`${end.date}T23:59:59`).toISOString()
      // await this.setBookings({groupId: 'bfc', start: min, end: max})
      // await this.setLoading(false)
      this.$emit("updateCalendarRange",{start,end})
    }
  }
}
</script>

