<template>
  <v-card>
    <v-data-table :items="events" :headers="headers" :search="search" :items-per-page="5" multi-sort>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.start="{ item }">
        {{ item.start | moment('DD MMM YYYY HH:mm') }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ item.end | moment('DD MMM YYYY HH:mm') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

export default {
  name: "BookingSystemTable",
  props:{
    events:{
      type: Array
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Booking For',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        {text: 'Made By', value: 'name'},
        {text: 'From', value: 'start'},
        {text: 'To', value: 'end'},
        {text: 'Actions', value: 'actions'}
      ],
      search:''
    }
  }
}
</script>

<style scoped>

</style>