import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"my-2",attrs:{"rounded":"lg","outlined":""}},[_c('div',[_c(VListItem,[_c(VListItemContent,{staticClass:"mt-2"},[_c(VListItemTitle,{staticClass:"subtitle"},[_vm._v(" My Future Bookings ")]),_c(VListItemSubtitle,{staticClass:"caption indigo--text"})],1),_c(VListItemAction,[_c('app-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.newBooking()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1)],1),_c(VDivider),_c(VItemGroup,[_c(VList,{attrs:{"dense":""}},[(!_vm.someBookings)?_c(VListItem,{staticClass:"rounded"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"subtitle"},[_vm._v(" You do not have any Bookings ")]),_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(" Why not start planning your next trip? ")])],1),_c(VListItemAction)],1):_vm._e(),_vm._l((_vm.items),function(item){return _c(VListItem,{key:item._id,staticClass:"rounded"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.start,'DD MMM'))+" "+_vm._s(item.category)+" ")]),_c(VListItemSubtitle,{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.start,'HH:mm'))+" - "+_vm._s(_vm._f("moment")(item.end,'HH:mm'))+" ")])],1),_c(VListItemAction,[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"fab":"","text":"","color":"primary"},on:{"click":function($event){return _vm.showEvent(item)}}},[_c(VIcon,[_vm._v("mdi-eye")])],1)],1)],1)],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }