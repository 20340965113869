import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VDataTable,{attrs:{"items":_vm.events,"headers":_vm.headers,"search":_vm.search,"items-per-page":5,"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer)],1)]},proxy:true},{key:"item.start",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.start,'DD MMM YYYY HH:mm'))+" ")]}},{key:"item.end",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.end,'DD MMM YYYY HH:mm'))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }